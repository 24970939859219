import axios from '../utils/index';
const settingsApi = {
    getQuestionCategory(callback) {
        axios
            .get(`/question-category-entity/list`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    updateCategory(data, callback) {
        axios
            .put(`/question-category-entity/update`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    addCategory(data, callback) {
        axios
            .post(`/question-category-entity/add`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    deleteCategory(data, callback) {
        axios
            .delete(`/question-category-entity/delete/${data.questionCategoryId}`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getQuestionList(data, callback) {
        axios
            .get(`/question-entity/list/${data.questionCategoryId}?pageNum=${data.pageNum}&pageSize=10`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    addQuestion(data, callback) {
        axios
            .post(`/question-entity/add`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getQuestionStatistics(data, callback) {
        axios
            .get(`/question-entity/statistics/${data.questionId}`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    editQuestion(data, callback) {
        axios
            .put(`/question-entity/update`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getQuestionInfo(data, callback) {
        axios
            .get(`/question-entity/info/${data.questionId}`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    delQuestion(data, callback) {
        axios
            .delete(`/question-entity/delete/${data.questionId}`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
};
export default settingsApi;
