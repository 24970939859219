import { Button, Modal, Popover, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import api from '@/api/prize';
import { defaultInfo } from '@/typings/allType';
import { useAliveController } from 'react-activation';
import './index.scss';
import TableBox from '@/components/tableBox';
import Pagination from '@/components/pagination';
const MemberPrize = props => {
    const navigate = useNavigate();
    const back = () => {
        navigate(-1);
    };
    useEffect(() => getList({ ...state, subscriptionId: memberActive }), []);
    const [state, setState] = useState({
        isSuspended: false,
        pageNo: 1,
    });
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [memberActive, setMemberActive] = useState(3);
    const memberList = [
        { tip: 'Gold - $120,000+ Maximum', label: 'Gold', value: 3 },
        { tip: 'Silver - $50,000+ Maximum', label: 'Silver', value: 2 },
        { tip: 'Bronze - $20,000 Maximum', label: 'Bronze', value: 1 },
    ];
    const changeTab = (val: defaultInfo) => {
        setTableData([]);
        setMemberActive(val.value);
        getList({ pageNo: 1, subscriptionId: val.value });
    };
    const [memberPrize, setMemberPrize] = useState<any>([]);
    const [unselMemberPrize, setUnselMemberPrize] = useState<any>([]);
    const [status, setStatus] = useState('showData');
    const [opeationModalOpen, setOpeationModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [totalRecord, setTotalRecord] = useState(100);
    const handlePageChange = prop => {
        setState({ ...state, pageNo: prop });
        getList({ ...state, pageNo: prop, subscriptionId: memberActive });
    };
    const getList = (val: defaultInfo) => {
        if (status === 'loading') return;
        const params = {
            pageNum: val.pageNo,
            pageSize: 10,
            subscriptionId: val.subscriptionId,
        };
        setStatus('loading');
        api.getMemberPrizeList(params, res => callback(res));
    };
    const callback = (res: defaultInfo) => {
        const { data, code, message } = res;
        if (code === 200) {
            if (data.records.length > 0) {
                const list = data.records.map(item => {
                    return Object.assign(item, {
                        firstDrawDateShow:
                            item.firstDrawDate && item.firstDrawDate !== null
                                ? window.format.foramtTimeDMYTime(+item.firstDrawDate)
                                : '-',
                    });
                });
                setTableData(list);
                setStatus('showData');
                setTotalRecord(data.total);
            } else {
                setStatus('showData');
                setTableData([]);
                setTotalRecord(data.total);
            }
        } else {
            setStatus('empty');
            window.format.alert('error', message);
        }
    };
    const [tableData, setTableData] = useState([]);
    const columns = [
        {
            title: 'Prize Name',
            dataIndex: '',
            key: 'prizeName',
            width: 450,
            render: val => <div className="cell-txt r-left">{val.prizeName ?? '-'}</div>,
        },
        {
            title: 'Points',
            dataIndex: '',
            key: 'drawPoints',
            render: val => <div className="cell-txt">{val.drawPoints ?? '-'}</div>,
        },
        {
            title: 'Mode',
            dataIndex: '',
            key: 'mode',
            render: val => <div className="cell-txt">{val.mode === 2 ? 'Cycle by days' : 'Once '}</div>,
        },
        {
            title: 'Cycle number',
            dataIndex: '',
            key: 'numberCycles',
            render: val => <div className="cell-txt">{val.numberCycles ?? '-'}</div>,
        },
        {
            title: 'Latest Draw Date',
            dataIndex: '',
            key: 'firstDrawDateShow',
            render: val => <div className="cell-txt">{val.firstDrawDateShow ?? '-'}</div>,
        },
        {
            title: '',
            dataIndex: '',
            key: 'prizeId',
            render: val => (
                <div className="cell-txt">
                    <i className="delete_icon" onClick={() => deleteMemPrize(val)}></i>
                </div>
            ),
        },
    ];
    const deleteMemPrize = val => {
        console.log(val);
        api.delMemberPrize({ memberPrizeId: val.memberPrizeId }, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                getList({ ...state, subscriptionId: memberActive });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const getUnselPrize = () => {
        api.getMemberUnselectedPrizeList({ subscriptionId: memberActive }, res => {
            const { data, code, message } = res;
            if (code === 200) {
                if (data !== null && data.length) {
                    setUnselMemberPrize(
                        data.map(item => {
                            return Object.assign(item, { choose: false });
                        })
                    );
                }
            } else {
                window.format.alert('error', message);
            }
        });
    };
    const addOrDelMemberPrize = (type: number, i: number, val: defaultInfo) => {
        if (type === 2) {
            // del
            memberPrize.splice(i, 1);
            setMemberPrize([...memberPrize]);
        } else {
            memberPrize.push(val);
            setMemberPrize([...memberPrize]);
            setPopoverOpen(false);
        }
        const index = unselMemberPrize.findIndex(v => v.prizeId === val.prizeId);
        unselMemberPrize[index].choose = !unselMemberPrize[index].choose;
        setUnselMemberPrize([...unselMemberPrize]);
    };
    const confirmSaveChoose = () => {
        setPopoverOpen(false);
        const pat = {
            prizeIds: memberPrize.map(item => item.prizeId),
            subscriptionId: memberActive,
        };
        api.addMemberPrize(pat, res => {
            const { data, code, message } = res;
            setLoading(false);
            if (code === 200) {
                setOpeationModalOpen(false);
                setMemberPrize([]);
                setUnselMemberPrize([]);
                getList({ ...state, subscriptionId: memberActive });
            } else {
                window.format.alert('error', message);
            }
        });
    };
    return (
        <>
            {status === 'loading' ? (
                <div className="data_loading flex-center" style={{ height: 'auto' }}>
                    {/* <Spin  /> */}
                    <Spin />
                </div>
            ) : (
                <></>
            )}
            {status === 'showData' ? (
                <div className="prize_detailMain page_mainBox">
                    <div className="r-left">
                        <div className="back_box r-left" onClick={back}>
                            <i className="back_icon"></i>
                            Back
                        </div>
                    </div>
                    <section>
                        <div className="r-left memberList_box">
                            {memberList.map((item, i) => {
                                return (
                                    <span
                                        key={`memprize-${i}`}
                                        onClick={() => changeTab(item)}
                                        className={`member-item ${memberActive === item.value ? 'active' : ''}`}
                                    >
                                        {item.label}
                                    </span>
                                );
                            })}
                        </div>
                        <div className="memberPoint">
                            {memberList[memberList.findIndex(v => v.value === memberActive)].tip}
                        </div>
                        <div className="prizeNum_box r-left">
                            Prizes: {totalRecord ?? 0}
                            <span
                                className="marLeft addPrizeBtn r-center"
                                onClick={() => {
                                    getUnselPrize();
                                    setOpeationModalOpen(true);
                                }}
                            >
                                Add prizes
                            </span>
                        </div>
                        <TableBox
                            status={status}
                            columns={columns}
                            data={tableData}
                            rowKey={val => val.prizeId}
                            height={false}
                            emptyText={{
                                emptyText: <div className="table_emptyBox">No prizes have been added</div>,
                            }}
                            className="is_clickable r-table table_main"
                            rowClassName={() => 'r-table_row'}
                        />
                        {status === 'showData' && tableData.length && totalRecord > 10 ? (
                            <Pagination total={totalRecord} pageChange={handlePageChange} pageNo={state.pageNo} />
                        ) : (
                            <></>
                        )}
                    </section>

                    <Modal
                        className="r-modal modal_no_padding"
                        maskClosable={false}
                        centered={true}
                        open={opeationModalOpen}
                        closeIcon={<></>}
                        footer={''}
                        key="member-prize-1"
                        style={{ width: 480 }}
                        title={[
                            <div className="modal-top-title" style={{ marginBottom: 30, textAlign: 'left' }}>
                                Add prize
                            </div>,
                        ]}
                    >
                        <div className="modal_form">
                            <div>
                                <p className="form_tip">Prize</p>
                                {memberPrize.map((item: defaultInfo, i: number) => {
                                    return (
                                        <div className="memberPrizeAdd_item r-left" key={`choosePrize-${i}`}>
                                            <span className="prizename_box ellipsis"> {item.prizeName}</span>
                                            <i
                                                className="del_icon"
                                                onClick={() => {
                                                    addOrDelMemberPrize(2, i, item);
                                                }}
                                            ></i>
                                        </div>
                                    );
                                })}
                                <Popover
                                    overlayClassName="prize_pop"
                                    placement="top"
                                    title={''}
                                    trigger="click"
                                    open={popoverOpen}
                                    onOpenChange={val => setPopoverOpen(val)}
                                    content={
                                        unselMemberPrize.length ? (
                                            unselMemberPrize.map((item: defaultInfo, i: number) => {
                                                return (
                                                    <div
                                                        key={`popChoose-prize-${item.prizeId}`}
                                                        className="prizePop_name ellipsis"
                                                        style={{
                                                            cursor: item.choose ? 'no-drop' : 'pointer',
                                                            backgroundColor: item.choose ? '#f2f2f2' : '#fff',
                                                        }}
                                                        onClick={() => {
                                                            if (item.choose) return;
                                                            addOrDelMemberPrize(1, i, item);
                                                        }}
                                                    >
                                                        {item.prizeName}
                                                    </div>
                                                );
                                            })
                                        ) : (
                                            <div className="prizePop_name r-center" style={{ cursor: 'initial' }}>
                                                No data
                                            </div>
                                        )
                                    }
                                    arrow={false}
                                >
                                    <div
                                        className="addMemberPrize_btn r-center"
                                        onClick={() => setPopoverOpen(!popoverOpen)}
                                    >
                                        + Add prize
                                    </div>
                                </Popover>
                            </div>
                            <div className="r-left" style={{ marginTop: 40 }}>
                                <Button
                                    className="r-emBorder_btn"
                                    type="primary"
                                    style={{ width: '100%', margin: '0 20px 0 0' }}
                                    onClick={() => {
                                        setPopoverOpen(false);
                                        setMemberPrize([]);
                                        setUnselMemberPrize([]);
                                        setOpeationModalOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className="r-primary_btn"
                                    type="primary"
                                    style={{ width: '100%' }}
                                    loading={loading}
                                    onClick={() => confirmSaveChoose()}
                                >
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default MemberPrize;
