import axios from '../utils/index';
const userApi = {
    getUserList(data, callback) {
        axios
            .get(`/admin/user/list?pageNum=${data.pageNum}&pageSize=10&startTime=${data.startTime}&endTime=${data.endTime}&keyword=${data.keyword}&subscriptionStatus=${data.status}`, {
            data,
        })
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getUserAwardList(data, callback) {
        axios
            .get(`/user-award-entity/list/${data.userId}?pageNum=${data.pageNum}&pageSize=10`, {
            data,
        })
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    disableUser(params, callback) {
        axios
            .post(`/admin/user/disabled`, params)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    deleteUser(params, callback) {
        axios
            .delete(`/admin/user/delete/${params.userId}`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getUserDetail(params, callback) {
        axios
            .put(`/admin/user/info/${params.userId}`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
};
export default userApi;
