import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    currentUserId: '',
    isGetList: false,
    feedBackCount: 0,
    locationFeedBackCount: 0,
};
export const stateSlice = createSlice({
    name: 'state',
    initialState,
    reducers: {
        setCurrentUserId: (state, action) => {
            state.currentUserId = action.payload;
        },
        setIsGetList: (state, action) => {
            state.isGetList = action.payload;
        },
        setFeedBackCount: (state, action) => {
            state.feedBackCount = action.payload;
        },
        setlocationFeedBackCount: (state, action) => {
            state.locationFeedBackCount = action.payload;
        },
    },
});
export const { setCurrentUserId, setIsGetList, setFeedBackCount, setlocationFeedBackCount } = stateSlice.actions;
export default stateSlice.reducer;
