var WorkTypesEnums;
(function (WorkTypesEnums) {
    WorkTypesEnums["ASSEMBLING"] = "Assembling";
    WorkTypesEnums["BUILDING_WORKS"] = "Building Works";
    WorkTypesEnums["CASTING"] = "Casting";
    WorkTypesEnums["COMMISSIONING"] = "Commissioning";
    WorkTypesEnums["CONCRETING"] = "Concreting";
    WorkTypesEnums["CONVERSION_WORKS"] = "Conversion Works";
    WorkTypesEnums["DECORATING"] = "Decorating";
    WorkTypesEnums["DESIGNING_DRAWING_WORKS"] = "Designing/Drawing Works";
    WorkTypesEnums["DEMOLITION"] = "Demolition";
    WorkTypesEnums["DUMPING"] = "Dumping";
    WorkTypesEnums["DISASSEMBLING"] = "Disassembling";
    WorkTypesEnums["DRAWING"] = "Drawing";
    WorkTypesEnums["DRILLING"] = "Drilling";
    WorkTypesEnums["ELECTRICAL_WORKS"] = "Electrical Works";
    WorkTypesEnums["EXCAVATING"] = "Excavating";
    WorkTypesEnums["FITTING"] = "Fitting";
    WorkTypesEnums["FOUNDATION_WORKS"] = "Foundation Works";
    WorkTypesEnums["INSTALLATION"] = "Installation";
    WorkTypesEnums["LANDSCAPING"] = "Landscaping";
    WorkTypesEnums["MAINTENANCE"] = "Maintenance";
    WorkTypesEnums["MARKING"] = "Marking";
    WorkTypesEnums["PAINTING"] = "Painting";
    WorkTypesEnums["PILING"] = "Piling";
    WorkTypesEnums["PLANT_SCAFFOLDING"] = "Plant/Scaffolding";
    WorkTypesEnums["PLASTERING"] = "Plastering";
    WorkTypesEnums["PLUMBING"] = "Plumbing";
    WorkTypesEnums["REFURBISHMENT"] = "Refurbishment";
    WorkTypesEnums["REDECORATION"] = "Redecoration";
    WorkTypesEnums["REPLACEMENT"] = "Replacement";
    WorkTypesEnums["REMOVAL"] = "Removal";
    WorkTypesEnums["RENOVATION"] = "Renovation";
    WorkTypesEnums["REVIEWING_DOCUMENTS"] = "Reviewing Documents";
    WorkTypesEnums["ROAD_WORKS"] = "Road Works";
    WorkTypesEnums["ROOFING"] = "Roofing";
    WorkTypesEnums["SCAFFOLDING"] = "Scaffolding";
    WorkTypesEnums["WELDING"] = "Welding";
    WorkTypesEnums["TESTING"] = "Testing";
    WorkTypesEnums["TILING"] = "Tiling";
})(WorkTypesEnums || (WorkTypesEnums = {}));
var JobTitleEnum;
(function (JobTitleEnum) {
    JobTitleEnum["EMPLOYER"] = "Employer";
    JobTitleEnum["EMPLOYERS_REPRESENTATIVE"] = "Employer\u2019s Representative";
    JobTitleEnum["CONTRACTOR"] = "Contractor";
    JobTitleEnum["LOCAL_PUBLIC_AUTHORITY"] = "Local/Public Authority";
    JobTitleEnum["SUB_CONTRACTOR"] = "Sub-Contractor";
    JobTitleEnum["ARCHITECT"] = "Architect";
    JobTitleEnum["CONTRACT_ADMINISTRATOR"] = "Contract Administrator";
    JobTitleEnum["FUNDER"] = "Funder";
    JobTitleEnum["QUANTITY_SURVEYOR"] = "Quantity Surveyor";
    JobTitleEnum["STATUTORY_UNDERTAKER"] = "Statutory Undertaker";
    JobTitleEnum["INSURANCE_PROVIDER"] = "Insurance Provider";
    JobTitleEnum["TENANT"] = "Tenant";
    JobTitleEnum["PURCHASER"] = "Purchaser";
    JobTitleEnum["OTHER_SPECIALIST_WORKER"] = "\u2018Other\u2019 Specialist Worker";
    JobTitleEnum["AGENTS"] = "Agents";
    JobTitleEnum["BROKER"] = "Broker";
    JobTitleEnum["CLERK_OF_WORKS"] = "Clerk of Works";
    JobTitleEnum["PARENT_COMPANY"] = "Parent Company";
})(JobTitleEnum || (JobTitleEnum = {}));
var CompanyTypeEnum;
(function (CompanyTypeEnum) {
    CompanyTypeEnum["LIMITED_LIABILITY_PARTNERSHIP"] = "Limited Liability Partnership";
    CompanyTypeEnum["PARTNERSHIP"] = "Partnership";
    CompanyTypeEnum["PRIVATE_LIMITED_COMPANY"] = "Private Limited Company (LTD)";
    CompanyTypeEnum["SOLE_TRADER"] = "Sole Trader";
})(CompanyTypeEnum || (CompanyTypeEnum = {}));
// 封装的方法
export function getUserPositionTypeText(typeValue) {
    return JobTitleEnum[typeValue] || typeValue;
}
export function getWorkTypesText(typeValue) {
    return WorkTypesEnums[typeValue] || typeValue;
}
export function getCompanyTypeText(typeValue) {
    return CompanyTypeEnum[typeValue] || typeValue;
}
var FileTypeEnum;
(function (FileTypeEnum) {
    FileTypeEnum["text/plain"] = "text";
    FileTypeEnum["text/html"] = "html";
    FileTypeEnum["application/json"] = "json";
    FileTypeEnum["image/jpeg"] = "image";
    FileTypeEnum["image/png"] = "image";
    FileTypeEnum["image/gif"] = "image";
    FileTypeEnum["audio/mpeg"] = "audio";
    FileTypeEnum["audio/wav"] = "audio";
    FileTypeEnum["video/mp4"] = "video";
    FileTypeEnum["video/quicktime"] = "video";
    FileTypeEnum["application/pdf"] = "pdf";
    FileTypeEnum["application/msword"] = "word";
    FileTypeEnum["application/vnd.ms-excel"] = "excel";
    FileTypeEnum["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"] = "xlsx";
    FileTypeEnum["application/vnd.openxmlformats-officedocument.wordprocessingml.document"] = "docx";
})(FileTypeEnum || (FileTypeEnum = {}));
export function getFileTypeText(typeValue) {
    const text = typeValue ? typeValue.split('/')[0] : '-';
    return FileTypeEnum[typeValue] || text;
}
