import { createSlice } from '@reduxjs/toolkit';
const initialState = {
    email: '',
    password: '',
    roleTitle: '',
};
export const userInfoSlice = createSlice({
    name: 'userInfo',
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setPassword: (state, action) => {
            state.password = action.payload;
        },
    },
});
export const { setEmail, setPassword } = userInfoSlice.actions;
export default userInfoSlice.reducer;
