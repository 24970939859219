import axios from '../utils/index';
const loginApi = {
    getSecret(callback) {
        axios
            .get(`/v1/public/rsa/get-publicKey`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    login(data, callback) {
        axios
            .post(`/admin/login`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    logOut(callback) {
        axios
            .post(`/admin/logout`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    forgotPassword(data, callback) {
        axios
            .post(`/admin/send/changPassword`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    updatePassword(data, callback) {
        axios
            .post(`/admin/email/updatePassword`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    checkValidatecode(params, callback) {
        axios
            .post(`/admin/checkSmsCode`, params)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
};
export default loginApi;
