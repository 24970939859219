import React from 'react';
import Index from '@/views/pages/index';
import UserAdmin from '@/views/pages/userAdmin';
import UserDetail from '@/views/pages/userAdmin/user-detail';
import PrizeAcquisitionRecord from '@/views/pages/userAdmin/prize-acquisition-record';
import Survey from '@/views/pages/survey';
import SurveyDetail from '@/views/pages/survey/survey-detail';
import Prize from '@/views/pages/prize';
import MemberPrize from '@/views/pages/prize/memberPrize';
import Subscription from '@/views/pages/subscription';
const Login = React.lazy(() => import('@/views/login'));
const ForgetPassword = React.lazy(() => import('@/views/login/forget'));
const ResetPassword = React.lazy(() => import('@/views/login/resetPassword'));
const routes = [
    {
        path: '/login',
        component: Login,
        exact: true,
        auth: false,
    },
    {
        path: '/forgetPassword',
        component: ForgetPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/resetPassword',
        component: ResetPassword,
        exact: true,
        auth: false,
    },
    {
        path: '/*',
        component: Index,
        exact: false,
        childrens: [
            {
                name: 'Users',
                path: '/user',
                component: UserAdmin,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'user-detail',
                path: '/user-detail',
                component: UserDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
            },
            {
                name: 'prize-acquisition-record',
                path: '/user-prize-acquisition-record',
                component: PrizeAcquisitionRecord,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
            },
            {
                name: 'Survey',
                path: '/survey',
                component: Survey,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
            },
            {
                name: 'SurveyDetail',
                path: '/survey-detail',
                component: SurveyDetail,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
            },
            {
                name: 'Prize',
                path: '/prize',
                component: Prize,
                exact: true,
                isShowOnNav: true,
                KeepAlive: true,
            },
            {
                name: 'MemberPrize',
                path: '/member-prize',
                component: MemberPrize,
                exact: true,
                isShowOnNav: false,
                KeepAlive: false,
            },
            {
                name: 'Subscriptions',
                path: '/subscription',
                component: Subscription,
                exact: true,
                isShowOnNav: true,
                KeepAlive: false,
            },
        ],
    },
];
export default routes;
