import axios from '../utils/index';
const subscriptionApi = {
    getSubscriptionList(data, callback) {
        axios
            .get(`/member-payment-records-entity/list?pageNum=${data.pageNum}&pageSize=10&startTime=${data.startTime}&endTime=${data.endTime}&keyword=${data.keyword}`, {
            data,
        })
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
};
export default subscriptionApi;
