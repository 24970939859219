import axios from '../utils/index';
const settingsApi = {
    getPrizeList(data, callback) {
        axios
            .get(`/prize-entity/list?pageNum=${data.pageNum}&pageSize=10&keyword=${data.keyword}`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    updatePrize(data, callback) {
        axios
            .put(`/prize-entity/update`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    addPrize(data, callback) {
        axios
            .post(`/prize-entity/add`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    changePrizeStatus(data, callback) {
        axios
            .put(`/prize-entity/updateStatus/${data.prizeId}?isOpen=${data.isOpen}`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    delPrizr(data, callback) {
        axios
            .delete(`/prize-entity/delete/${data.prizeId}`)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getMemberPrizeList(data, callback) {
        axios
            .get(`/member-prize-entity/list/${data.subscriptionId}?pageNum=${data.pageNum}&pageSize=10`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getMemberUnselectedPrizeList(data, callback) {
        axios
            .get(`/member-prize-entity/list/unselected/${data.subscriptionId}`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    addMemberPrize(data, callback) {
        axios
            .post(`/member-prize-entity/add`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    delMemberPrize(data, callback) {
        axios
            .delete(`/member-prize-entity/delete/${data.memberPrizeId}`, data)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
};
export default settingsApi;
