import axios from '../utils/index';
const uploadApi = {
    uploadFile(params, callback) {
        axios
            .post(`/common/upload/file`, params)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    uploadMultiFile(params, callback) {
        axios
            .post(`/admin/upload/multi-file`, params)
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
    getFilePresignedUrl(params, callback) {
        axios
            .get(`/admin/upload/presigned-url`, { params })
            .then(res => {
            callback(res.data);
        })
            .catch(err => {
            callback(err);
        });
    },
};
export default uploadApi;
